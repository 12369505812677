import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { voidTransactionLineItem } from '../../services/transaction.service';
import Dialog from '../ui/Dialog';
import { Grid, TextField } from '@material-ui/core';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';

const VoidLineItem = ({ data }) => {
    const [openModal, setOpenModal] = useState(false);
    const [openConfirmation, setConfirmation] = useState(false);
    const [reason, setReason] = useState("");
    const [isLoading, setLoading] = useState(false);
    const { transaction_line_item_id, voided_at, program_id } = data;

    const onSubmit = async () => {
        if (window.confirm('Do you want to void this line item?')) {
            setLoading(true);
            await voidTransactionLineItem({
                connect_program_id: program_id,
                transaction_line_item_id,
                payload: {
                    reason_for_voiding: reason
                }
            })
            .then((res) => {
                alert(res.message);
                handleClose(true);
            })
            .catch((err) => alert(err.message))
            .finally(() => {
                setLoading(false);
            })
        }
    };
    
    const handleClose = (reload) => {
        setOpenModal(false);
        setConfirmation(false);

        if (reload) {
            window.location.reload();
        }
    }
    
    const formButtons = (
        <>
            <Button onClick={() => handleClose(false)} variant='contained'>
            Close
            </Button>
            <Button onClick={onSubmit} variant='contained' color='secondary'>
            Submit
            </Button>
        </>
    );

    const formContent = (
        <>
          <Grid
            item
            container
            xs={12}
            spacing={2}
          >
            <Grid item xs={12}>
                <TextField 
                    multiline={true}
                    rows={4}
                    label='Reason'
                    required={true}
                    name='reason' 
                    variant='outlined'
                    onChange={(e) => setReason(e.target.value)} 
                />
            </Grid>
          </Grid>
        </>
    );

    if (isLoading) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <Dialog
                key='void-line-item'
                open={openModal}
                title = 'Void Receipt'
                content = {formContent} 
                children = {formButtons} 
                onSubmit = {onSubmit}
                onClose = {() => handleClose(false)} 
            />
            <Dialog 
                key='confirmation'
                open={openConfirmation}
                title = 'Confirmation'
                content = 'Line item successfully voided!' 
                children = {(
                    <Button onClick={() => handleClose(true)} variant='contained'>
                    Close
                    </Button>
                )} 
                onSubmit = {onSubmit}
                onClose = {() => handleClose(true)} 
            />
            {
                voided_at === null && 
                <Button variant="contained" color="secondary" onClick={() => setOpenModal(true)} 
                style={{ marginRight: 8 }}>Void</Button>
            }
        </>
    )
}

export default VoidLineItem;