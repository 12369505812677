import apiClient from '../utilities/apiClient.utility';

const userUrl = `${window.config.CONNECT_URLS.users}`;

export const postUpdatePassword = async (payload) => {
    return await apiClient.post(
      `${userUrl}/${window.programConfig.program_id}/profile/update-password`,
      payload
    );
};

export const activateUser = async (payload) => {
  const { program_id, id } = payload;
  return await apiClient.post(
    `${userUrl}/${program_id}/dashboard/user/${id}/activate`, {}
  );
}

export const deactivateUser = async (payload) => {
  const { program_id, id } = payload;
  return await apiClient.post(
    `${userUrl}/${program_id}/dashboard/user/${id}/deactivate`, {}
  );
}

export const updateUserInfo = async ({ program_id, id, payload }) => {
  return await apiClient.put(
    `${userUrl}/${program_id}/dashboard/user/${id}`, payload
  );
}

export const updateUserChasCard = async ({ program_id, id, payload, action }) => {
  return await apiClient.post(
    `${userUrl}/${program_id}/dashboard/user/${id}/chas/${action}`, payload
  );
}

export const manuallyCreateUser = async ({ program_id, payload }) => {
  return await apiClient.post(
    `${userUrl}/${program_id}/dashboard/user/manual-pre-registration`, payload
  );
}

export const sendHcpPrefilledLink = async ({ program_id, payload }) => {
  return await apiClient.post(
    `${userUrl}/${program_id}/dashboard/user/send-prefilled-link`, payload
  );
}

export const getAllDoctors = async ({ program_id }) => {
  return await apiClient.get(
    `${userUrl}/${program_id}/dashboard/user/getHCPList?user_type=doctor&order_by=full_name`
  );
}

export const getFieldList = async ({ program_id, name }) => {
  return await apiClient.get(
    `${userUrl}/${program_id}/dashboard/user-fields/${name}`
  );
}

export const approvePatientRegistration = async (payload) => {
  const { program_id, id } = payload;
  return await apiClient.post(
    `${userUrl}/${program_id}/dashboard/user-registration/${id}/approve`, {}
  );
}

export const rejectPatientRegistration = async (payload) => {
  const { program_id, id } = payload;
  return await apiClient.post(
    `${userUrl}/${program_id}/dashboard/user-registration/${id}/reject`, {}
  );
}