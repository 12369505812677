import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { approvePatientRegistration, rejectPatientRegistration } from '../../services/user.service';
import { FormControl, FormLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import Dialog from '../ui/Dialog';

const PatientRegistrationApproval = ({ data }) => {
    const { patient_id, status, program_id } = data;
    const [openModal, setOpenModal] = useState(false);
    const [openConfirmation, setConfirmation] = useState(false);
    const [modalTitle, setModalTitle] = useState("Approval / Rejection of Patient Registration");
    const [successMessage, setSuccessMessage] = useState('');
    const [rejectionNotes, setRejectionNotes] = useState("");
    const useStyles = makeStyles(() => ({
        field: {
          marginBottom: 20,
        },
        formControl: {
          width: '100%'
        }
    }));
      
    const classes = useStyles();
 
    const onClick = async (action: string) => {
        if (window.confirm(`Do you want to ${action} this patient?`)) {
            if (action === "approve") {
                try {
                    const res = await approvePatientRegistration({
                        id: patient_id,
                        program_id,
                    });
    
                    alert(res.message);
                    window.location.reload();
                } catch(err) {
                    alert(err.message)
                }
            }

            if (action === "reject") {
               setOpenModal(true);
            }
        }
    };

    const onReject = async () => {
        try {
            const res = await rejectPatientRegistration({
                id: patient_id,
                program_id,
                registration_rejection_notes: rejectionNotes
            });

            setOpenModal(false);

            setSuccessMessage(res.message);
            setConfirmation(true);

            window.location.reload();
        } catch(err) {
            alert(err.message);
        }
    };

    if (status !== "pre-approval") {
        return <></>;
    }

    const formContent = (
        <>
        <FormControl className={classes.formControl}>
            <FormLabel>
                <Typography>
                    Rejection Notes
                </Typography>
            </FormLabel>
            <TextField className={classes.field} 
                required={true} 
                type="text" 
                onChange={(e) => {
                    const { value } = e.target;
                    setRejectionNotes(value);
                }}
            />
        </FormControl>
        </>
    );

    const handleClose = () => {
        setOpenModal(false);
        setRejectionNotes("");
        setConfirmation(false);
      }

    const formButtons = (
        <>
            <Button onClick={handleClose} variant='contained'>
                Close
            </Button>
            <Button onClick={onReject} variant='contained' color='secondary'>
                Submit
            </Button>
        </>
      );

    return (
        <>
            <Dialog
                key='patient-registation-approval'
                open={openModal}
                title = {modalTitle}
                content = {formContent}
                children = {formButtons}
                onSubmit={onReject}
                onClose={handleClose}
            />
            <Dialog
                key='confirmation'
                open={openConfirmation}
                title = 'Confirmation'
                content = {successMessage}
                children = {formButtons}
                onSubmit={null}
                onClose={handleClose}
            />
            <Button color="primary" variant="contained" onClick={() => onClick('approve')} style={{ marginRight: 8 }} >Approve</Button>
            <Button variant="contained" onClick={() => onClick('reject')} style={{ marginRight: 8 }}>Reject</Button>
        </>
    )
}

export default PatientRegistrationApproval;
