import React, { useState } from 'react';
import { Button, makeStyles, TextField, FormControl, FormLabel, Typography } from '@material-ui/core';
import DatePicker from '../DatePicker/DatePicker.component';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import { freeGoodTransactionConfirmation, freeGoodTransactionManualUpdate } from '../../services/transaction.service';
import CreateIcon from '@material-ui/icons/Create';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 20,
  },
  formControl: {
    width: '100%'
  }
}));

const currentDate = new Date();
const ACTION_CONFIRM = 'confirm';
const ACTION_UPDATE = 'update';
const ACTION_DISAPPROVE = 'disapprove';

const FreeGoodConfirmation = ({ data }) => {
  const { transaction_code_id, patient_id, patient_name, quantity, so_number, program_id } = data;
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [manualActionData, setManualActionlData] = useState({
    collection_date: currentDate,
    quantity,
    so_number
  });
  const [action, setAction] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('FOC Collection confirmed!');
  const [isLoading, setLoading] = useState(false);
  const handleClickAction = (action) => {
    setOpenModal(true);
    setAction(action);
    let modalTitle = 'Free Goods Quantity Update';
    if (program_id.includes('lets-get-started-fraizeron')) {
      modalTitle = 'Free Goods Update';
    }
    setModalTitle(modalTitle);
    if (action === ACTION_DISAPPROVE) {
      setModalTitle('Free Goods Status (Voided)');
    }

    if (action === ACTION_CONFIRM) {
      setModalTitle('Free Goods Status (Confirmed)');
    }
  }
  const handleClose = () => {
    setOpenModal(false);
    setManualActionlData({
      collection_date: currentDate,
      quantity,
    });
    setAction(null);
    setConfirmation(false);
    setErrorMessage(null);
    if (action === null) {
      window.location.reload();
    }
  }
 
  const classes = useStyles();
  const onSubmit = async () => {
    try {
      if (!manualActionData.collection_date && action === ACTION_CONFIRM) {
        setErrorMessage('Collection Date is required');

        return;
      }

      if (action === ACTION_UPDATE) {
        if (!program_id.includes('lets-get-started-fraizeron')) {
          if (!manualActionData.quantity) {
            setErrorMessage('Quantity is required');
  
            return;
          }
  
          // if (manualActionData.quantity === quantity) {
            // setErrorMessage('Quantity should not be the same as current');
  
            // return;
          // }
  
          if (manualActionData.quantity < 1) {
            setErrorMessage('Quantity should be greater than 0');
  
            return;
          }
        }
      }

      setLoading(true);
      let details = {};
      if (manualActionData.collection_date && action === ACTION_CONFIRM) {
        details.collection_date = manualActionData.collection_date;
      }

      if (manualActionData.quantity) {
        details.number_free_goods = manualActionData.quantity;
      }

      if (manualActionData.so_number) {
        details.so_number = manualActionData.so_number;
        details.so_number_updated_at = moment().toJSON();
      }
      
      const payload = {
        manual_action_details: details,
      };

      await freeGoodTransactionConfirmation(
        {
          connect_program_id: program_id,
          transaction_code_id,
          action,
          payload,
        }
      )

      handleClose();
      let successMsg = 'Free Goods quantity updated!';
      if (program_id.includes('lets-get-started-fraizeron')) {
        successMsg = 'Free Goods updated!';
      }
      if (action === ACTION_UPDATE) {
        setSuccessMessage(successMsg);
      }
      setConfirmation(true);
    } catch(err) {
      setErrorMessage('Unable to update free goods');
    } finally {
      setLoading(false);
    }
  }

  const onCollectionDateChange = (date) => {
    setManualActionlData(prevState => ({
      ...prevState,
      collection_date: date
    }));
  }

  const onFieldInput = (e) => {
    const { name, value } = e.target;

    setManualActionlData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const formContent = (
    <>
      <FormControl className={classes.formControl}>
        <FormLabel className={classes.field}>
          <Typography>
            Patient ID
          </Typography>
          <Typography>
          { patient_id }
          </Typography>
        </FormLabel>
      </FormControl>
      <FormControl className={classes.formControl}>
        <FormLabel className={classes.field}>
          <Typography>
            Patient Name
          </Typography>
          <Typography>
          { patient_name }
          </Typography>
        </FormLabel>
      </FormControl>

      {action === ACTION_UPDATE && 
        <>
          {
            program_id.includes('lets-get-started-fraizeron') ? 
            <FormControl className={classes.formControl}>
              <FormLabel >
                <Typography>
                  SO #
                </Typography>
              </FormLabel>
              <TextField className={classes.field} 
                required={true} 
                name='so_number'
                type="text" 
                defaultValue={so_number}  
                onChange={onFieldInput}
              />
            </FormControl> : 
            <FormControl className={classes.formControl}>
              <FormLabel >
                <Typography>
                  Quantity
                </Typography>
              </FormLabel>
              <TextField className={classes.field} 
              required={true} 
              name='quantity'
              type='number' 
              defaultValue={quantity}  
              onChange={onFieldInput}
              />
           </FormControl>
          }
        </>
      }

      {action === ACTION_CONFIRM &&
        <FormControl className={classes.formControl}>
      <FormLabel>
          <Typography>
            Collection Date
          </Typography>
        </FormLabel>
        <DatePicker 
          required={true}
          onChange={onCollectionDateChange}
          name='collection_date'
          className={classes.field} 
          value={manualActionData.collection_date}
          maxDate={currentDate}
        />
      </FormControl>
      }
      <Typography
        variant='caption'
        display='block'
        gutterBottom
        color='error'
      >
        { errorMessage }
      </Typography>
    </>
  );

  const formButtons = (
    <>
      <Button onClick={handleClose} variant='contained'>
        Close
      </Button>
      {action !== null &&
        <Button onClick={onSubmit} variant='contained' color='secondary'>
        Submit
        </Button>
      }
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog 
      key='free-good-details'
      open={openModal}
      title = {modalTitle}
      content = {formContent}
      children = {formButtons}
      >
     </Dialog>
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = {successMessage}
      children = {formButtons}
      >
     </Dialog>
     <Button variant='contained' color='secondary' onClick={() => handleClickAction(ACTION_UPDATE)}>
        <CreateIcon></CreateIcon>
      </Button>&nbsp;
      <Button variant='contained' color='primary' onClick={() => handleClickAction(ACTION_CONFIRM)}>
        Confirmed
      </Button>&nbsp;
      {/* <Button variant='contained' onClick={() => handleClickAction(ACTION_DISAPPROVE)}>
        Voided
      </Button> */}
    </>
  )
};

export default FreeGoodConfirmation;